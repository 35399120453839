import React, { useEffect, useState } from 'react';
import { AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TruncatedText from 'components/@shared/TruncatedText';
import Gap from 'components/@shared/Gap';
import Checkbox from 'components/@shared/Checkbox';
import { formatNumber } from 'utils/formatNumber';

import { useTranslation } from 'react-i18next';
import { StyledAccordion, StyledSlider, StyledFromToContainer } from './styled';
import { Price } from '../types';

type Props = {
  className?: string;
  min: number;
  max: number;
  initialExpanded?: boolean;
  value: Price;
  onChange: (value: Price) => void;
};

const FilterPriceSection: React.FC<Props> = ({ min, max, initialExpanded = false, value, className, onChange }) => {
  const { t } = useTranslation();

  const { priceFrom, priceTo } = value;

  const [expanded, setExpanded] = useState(initialExpanded);
  const [free, setFree] = useState(false);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const [from, to] = newValue as [number, number];
    onChange({ priceFrom: from, priceTo: to });
  };

  useEffect(() => {
    if (free) {
      onChange({ priceFrom: 0, priceTo: 0 });
    } else {
      onChange({ priceFrom: min, priceTo: max });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [free]);

  return (
    <StyledAccordion className={className} expanded={expanded} onChange={() => setExpanded(prevState => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TruncatedText variant="subtitle2" color="primary.main">
          {t('servicesPage.filterByPriceLabel')}
        </TruncatedText>
      </AccordionSummary>
      <AccordionDetails>
        <Gap size={16} />
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography variant="subtitle2" color="primary.dark2">
            {formatNumber(min, { maximumFractionDigits: 0 })}
          </Typography>
          <StyledSlider
            value={[priceFrom ?? min, priceTo ?? max]}
            onChange={handleChange}
            disableSwap
            disabled={free}
            min={min}
            max={max}
          />
          <Typography variant="subtitle2" color="primary.dark2">
            {formatNumber(max, { maximumFractionDigits: 0 })}
          </Typography>
        </Stack>
        <StyledFromToContainer show={priceFrom !== null && priceTo !== null}>
          <Gap size={11} />
          <Typography variant="subtitle2" color="primary.main">
            {t('servicesPage.filterByPriceFromTo', {
              from: priceFrom !== null ? formatNumber(priceFrom, { maximumFractionDigits: 0 }) : null,
              to: priceTo !== null ? formatNumber(priceTo, { maximumFractionDigits: 0 }) : null,
            })}
          </Typography>
        </StyledFromToContainer>
        <Gap size={16} />
        <Checkbox
          label={t('servicesPage.filterByPriceFreeLabel')}
          value={free}
          onChange={(_, checked) => setFree(checked)}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default FilterPriceSection;
