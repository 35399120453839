import { useNavigate } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { CLIENT_RESERVATIONS } from 'api/requests/client';
import { OutputReservationDto, ReservationStatus } from 'types/reservation';
import dayjs from 'dayjs';
import getRouteWithParams from 'utils/getRouteWithParams';
import { PATHS } from 'navigation/constants';
import { CANCEL_RESERVATION } from 'api/requests/service';
import useMutation from 'hooks/useMutation';

const useReservationsTab = () => {
  const navigate = useNavigate();

  const [getClientReservations, { loading }] = useLazyQuery<
    { clientReservations: OutputReservationDto[] },
    {
      statuses: ReservationStatus[];
    }
  >(CLIENT_RESERVATIONS, {
    fetchPolicy: 'no-cache',
  });

  const [cancelReservation] = useMutation<
    { cancelReservation: { id: number } },
    {
      reservationId: number;
    }
  >(CANCEL_RESERVATION, {
    fetchPolicy: 'no-cache',
  });

  const [clientReservations, setClientReservations] = useState<OutputReservationDto[]>([]);
  const [cancelReservationLoadings, setCancelReservationLoadings] = useState<number[]>([]);

  useEffect(() => {
    getClientReservations({
      variables: { statuses: [ReservationStatus.WAITING_FOR_APPROVE, ReservationStatus.APPROVED] },
    }).then(res => {
      if (res.data?.clientReservations) {
        setClientReservations(res.data.clientReservations);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reservations = useMemo(() => {
    return clientReservations.reduce<{
      active: OutputReservationDto[];
      archived: OutputReservationDto[];
    }>(
      (acc, item) =>
        dayjs().isBefore(item.date)
          ? { ...acc, active: [...acc.active, item] }
          : {
              ...acc,
              archived: [...acc.archived, item],
            },
      { active: [], archived: [] },
    );
  }, [clientReservations]);

  const handleBookAgain = (id: number) =>
    navigate(getRouteWithParams(PATHS.BOOKING_SERVICE, { serviceId: id.toString() }));

  const handleCancelReservation = async (reservationId: number) => {
    try {
      setCancelReservationLoadings(prev => [...prev, reservationId]);
      await cancelReservation({ variables: { reservationId } });
      setClientReservations(prev => prev.filter(item => item.id !== reservationId));
    } catch (e) {
      console.log(e);
    } finally {
      setCancelReservationLoadings(prev => prev.filter(item => item !== reservationId));
    }
  };

  const handleServiceClick = (id: number) =>
    navigate(getRouteWithParams(PATHS.SERVICE_DETAILS_PAGE, { serviceId: id.toString() }));

  return {
    reservations,
    loading,
    cancelReservationLoadings,
    handleBookAgain,
    handleCancelReservation,
    handleServiceClick,
  };
};

export default useReservationsTab;
