import React from 'react';
import { ClickAwayListener, Popper, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowRightSquareIcon } from 'assets/icons/arrowRightSquare.svg';
import { ReactComponent as ArrowLeftSquareIcon } from 'assets/icons/arrowLeftSquare.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import 'dayjs/locale/uk';
import dayjs, { Dayjs } from 'dayjs';
import IconButton from 'components/@shared/IconButton';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import i18n from 'localizations';
import {
  StyledContainer,
  StyledMonthButton,
  StyledPopperContainer,
  StyledWeekCell,
  StyledWhiteContainer,
} from './styled';
import { ReservationCalendarVariant } from '../types';

dayjs.extend(weekday);
dayjs.extend(isoWeek);

export type Props = {
  date: Dayjs;
  variant: ReservationCalendarVariant;
  onChange: (date: Dayjs) => void;
};

const getMonthList = (day: Dayjs) => {
  let currentMonth = day.startOf('month');
  const list = [currentMonth];

  for (let i = 0; i < 11; i++) {
    currentMonth = currentMonth.add(1, 'month');
    list.push(currentMonth);
  }

  return list;
};

const getWeekDays = (day: Dayjs) => {
  const startOfWeek = day.locale(i18n.language).startOf('week');

  const week = Array.from({ length: 7 }, (_, index) => {
    return startOfWeek.add(index, 'days');
  });

  return week;
};

const Header: React.FC<Props> = ({ date, onChange, variant }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleClickAway = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const unit = variant === ReservationCalendarVariant.List ? 'month' : 'week';

  const startOfUnit = date.locale(i18n.language).startOf(unit);

  const startOfMonth = date.locale(i18n.language).startOf('month');

  const disablePrev = startOfUnit.isSame(dayjs().locale(i18n.language).startOf(unit));

  return (
    <StyledWhiteContainer className="reservation-calendar--header">
      <StyledContainer variant={variant}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledMonthButton
              aria-describedby={id}
              direction="row"
              alignItems="center"
              gap="12px"
              onClick={handleClick}
            >
              <Typography variant="subtitle1">{date.locale(i18n.language).format('MMMM YYYY')}</Typography>
              <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 1 }}>
                <StyledPopperContainer>
                  {getMonthList(dayjs()).map(item => {
                    const value = item.locale(i18n.language).format('MMMM YYYY');
                    return (
                      <Typography
                        key={value}
                        variant="subtitle1"
                        color={startOfMonth.isSame(item) ? 'accent.main' : 'primary.main'}
                        onClick={() => onChange(item)}
                      >
                        {value}
                      </Typography>
                    );
                  })}
                </StyledPopperContainer>
              </Popper>
            </StyledMonthButton>
          </ClickAwayListener>
          <Stack direction="row" alignItems="center" gap="8px">
            <IconButton disabled={disablePrev} onClick={() => onChange(startOfUnit.subtract(1, unit))}>
              <ArrowLeftSquareIcon />
            </IconButton>
            <IconButton onClick={() => onChange(startOfUnit.add(1, unit))}>
              <ArrowRightSquareIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" data-week-days width="100%" height="40px">
          <Stack width="100%" height="100%">
            <Stack height="20px" />
            <StyledWeekCell />
          </Stack>
          {getWeekDays(date).map(item => (
            <Stack width="100%" height="100%" key={item.format()}>
              <Stack height="20px" alignItems="center">
                {item.format('dd').toLocaleUpperCase()}
              </Stack>
              <StyledWeekCell>{item.format('DD')}</StyledWeekCell>
            </Stack>
          ))}
        </Stack>
      </StyledContainer>
    </StyledWhiteContainer>
  );
};

export default Header;
