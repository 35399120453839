export default {
  comingSoon: 'Coming soon!!!',
  loadingText: 'Searching...',
  loadingStateMessage: 'Please wait...',
  noOptionsText: 'No matches found',
  discard: 'Cancel',
  saveChanges: 'Save Changes',
  scheduleTitle: 'Working Hours',
  from: 'from',
  to: 'to',
  moreDetails: 'More Details',
  bookButtonLabel: 'Book Now',
  allServices: 'Services',
  examples: 'Portfolio',
  name: 'Name',
  back: 'Back',
  continue: 'Continue',
  week: 'Week',
  schedule: 'Schedule',
  filters: 'Filters',
  priceLabel: 'Price',
  price: '{{val, number}} USD',
  priceFree: 'Free',
  toFavoritesButtonLabel: 'Add to Favorites',
  master: 'Specialist',
  duration: 'Duration',
  day: 'Day',
  date: 'Date',
  time: 'Time',
  phone: 'Phone',
  town: 'City',
  notSelected: 'Not Selected',
  delete: 'Delete',
  confirm: 'Confirm',
  showMore: 'Show More',
  service: 'Service',
  cancel: 'Cancel',
  confirmDelete: 'Yes, Delete',
  hoursShort: 'hr',
  minutesShort: 'min',
  confirmCancelReservationTitle: 'Are you sure you want to cancel booking?',
  confirmCancelReservationButtonLabel: 'Confirm',

  slug: {
    title: 'Unique URL',
    errorMessage: 'This URL is already taken. Please enter another, or choose from the suggestions:',
    reservedErrorMessage: 'This URL is reserved. Please enter another',
  },

  validation: {
    fieldIsRequired: 'This field is required',
    mobileNumberIsInvalid: 'Invalid mobile number',
    emailNumberIsInvalid: 'Invalid email address',
    scheduleCountInvalid: 'Working hours must be added',
    activitiesCountInvalid: 'Activities and Experience must be added',
  },

  socialMedia: {
    instagram: 'Instagram',
    facebook: 'Facebook',
    telegram: 'Telegram',
    connect: 'Link Account',
    confirm: 'Confirm',
    disconnect: 'Unlink Account',
    linkInputLabel: 'URL',
    linkInputPlaceholder: 'Enter URL',
  },

  breadcrumbs: {
    home: 'Home',
    services: 'Services',
    serviceFallback: 'Service Provider',
    authenticate: 'Log In',
    favorites: 'Favorites',
    about: 'About Us',
    termsAndConditions: 'Terms of Use',
    'privacy-policy': 'Privacy Policy',
    cabinet: 'Dashboard',
    examples: 'Portfolio',
    addService: 'Add Service',
    bookService: 'Book Service',
    calendar: 'Calendar',
    reservationDetails: 'Booking Details',
    addMaster: 'Add Specialist',
  },

  category: {
    notSelected: 'Not Selected',
    art: 'Creativity',
    auto: 'Automotive',
    beauty: 'Beauty',
    consultations: 'Consultations',
    medicine: 'Medical',
    pets: 'Pets',
    sport: 'Sports',
    education: 'Education',
  },

  accountType: {
    client: 'Client',
    company: 'Company',
    privateSpecialist: 'Independent Professional',
  },

  dayOfWeek: {
    sun: 'Sunday',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
  },

  feedback: {
    1: '({{count}} review)',
    2: '({{count}} reviews)',
    3: '({{count}} reviews)',
    4: '({{count}} reviews)',
    unspecific: '({{count}} reviews)',
  },

  result: {
    1: '{{count}} result',
    2: '{{count}} results',
    3: '{{count}} results',
    4: '{{count}} results',
    unspecific: '{{count}} results',
  },

  mainPage: {
    title: 'Convenient service search and assistance during the war',
    subtitle:
      'We are an online platform designed to quickly and safely connect those in need of services with those ready to help.',
    popularTitle: 'Popular Services',
    allServicesButtonLabel: 'All Services',
    signupButtonLabel: 'Register',
    overviewSectionTitle: 'I Want to Help',
    overviewSectionSubtitle: 'If you want and can offer your services – create your page and publish them.',
    overviewSectionActionButtonLabel: 'I Want to Become a Partner',
  },

  servicesPage: {
    title: 'Services',
    filtersTitle: 'Filters',
    filtersSubtitle: 'Choose a Category',
    filterByPriceLabel: 'Price, USD',
    filterByPriceFromTo: 'from {{from}} to {{to}}',
    filterByPriceFreeLabel: 'Free',
    filtersButtonLabelLabel: 'Apply filters',
    sortButtonLabel: 'Sort By',
    emptyStateMessage: 'No services found',
  },

  serviceDetailsPage: {
    allServicesTitle: 'Services',
    examplesTitle: 'Portfolio',
  },

  contactsSection: {
    title: 'Contacts',
    phoneContactLabel: 'Phone',
    phoneContactShowButtonLabel: 'Show Number',
    emailContactLabel: 'Email',
    scheduleContactLabel: 'Working Hours',
    addressContactLabel: 'Address',
  },

  termsAndConditionsPage: {
    title: 'Terms of Use for the KeepSer Platform',
    item1Title: 'General Provisions',
    item1Details: `
    <ul>
      <li>The Terms of Use (hereinafter referred to as the "Terms") govern the usage of the KeepSer platform, which is developed and maintained by LLC "SkilledBit" (hereinafter referred to as the "Company").</li>
      <li>By using the platform, you agree to these Terms, as well as the Privacy Policy. If you do not agree, please refrain from using the platform.</li>
      <li>The Company reserves the right to change the Terms at any time. The new version of the Terms takes effect upon its publication on the platform.</li>
    </ul>
  `,
    item2Title: 'Services Provided by the Platform',
    item2Details: `
    <ul>
      <li>KeepSer provides users with services for searching, reviewing, and monitoring various trading strategies.</li>
      <li>The conditions for using the services are defined by the Company and may change depending on the platform's functionality development.</li>
    </ul>
  `,
    item3Title: 'User Account',
    item3Details: `
    <ul>
      <li>To access the platform's full functionality, account registration is required.</li>
      <li>The user must ensure the accuracy of the information in their profile, follow security requirements, and not share access data with third parties.</li>
    </ul>
  `,
    item4Title: 'User Responsibilities',
    item4Details: `
    <ul>
      <li>The user agrees to use the platform only in accordance with applicable laws and the Terms of Use.</li>
      <li>The Company is not liable for any damages caused to the user as a result of non-compliance with these Terms or legal requirements.</li>
    </ul>
  `,
    item5Title: 'Termination of Platform Access',
    item5Details: `
    <ul>
      <li>The Company has the right to suspend or terminate the user’s access to the platform in case of a Terms violation or at the request of relevant authorities.</li>
    </ul>
  `,
    item6Title: 'Intellectual Property',
    item6Details: `
    <ul>
      <li>All intellectual property rights to the KeepSer platform, including text, graphics, logos, and software, belong to the Company.</li>
      <li>Users are prohibited from copying, modifying, or distributing platform content without the Company’s written consent.</li>
    </ul>
  `,
  },

  privacyPolicyPage: {
    title: 'Privacy Policy for the KeepSer Platform',
    description:
      'This Privacy Policy describes how LLC "SkilledBit" (hereinafter referred to as the "Company") collects, uses, stores, and discloses personal data of users interacting with the KeepSer platform.',
    item1Title: 'Data We Collect',
    item1Details: `
    <ul>
      <li><b>User Information:</b> data you enter during registration, including your name, email address, and phone number.</li>
      <li><b>Technical Data:</b> IP address, information about your device, and Cookies to enhance platform functionality and ensure security.</li>
    </ul>
  `,
    item2Title: 'Purpose of Data Collection',
    item2Details: `
    <ul>
      <li>Data is collected to provide services, improve platform quality, personalize content, and offer technical support.</li>
      <li>Information may be used for analytics to enhance the platform and for marketing purposes (with your consent).</li>
    </ul>
  `,
    item3Title: 'Data Disclosure to Third Parties',
    item3Details: `
    <ul>
      <li>We do not disclose your data to third parties without your consent, except as required by law.</li>
      <li>Partners ensuring platform functionality are also obligated to adhere to privacy principles under current agreements.</li>
    </ul>
  `,
    item4Title: 'Data Protection',
    item4Details: `
    <ul>
      <li>We take all necessary measures to protect your personal data from unauthorized access, use, or disclosure.</li>
    </ul>
  `,
    item5Title: 'User Rights',
    item5Details: `
    <ul>
      <li>You have the right to access, correct, or delete your data, restrict processing, and withdraw consent for data processing.</li>
      <li>To exercise these rights, you can contact our support service.</li>
    </ul>
  `,
    item6Title: 'Cookies and Similar Technologies',
    item6Details: `
    <ul>
      <li>We use Cookies to analyze traffic, configure platform operations, and ensure the efficiency of user sessions.</li>
      <li>You can limit the use of Cookies by changing your browser settings.</li>
    </ul>
  `,
    item7Title: 'Changes to the Privacy Policy',
    item7Details: `
    <ul>
      <li>We may periodically update this Policy, and if changes are made, we will notify you via email or through the platform.</li>
    </ul>
  `,
  },

  forCompaniesPage: {
    title: 'KeepSer — A Platform for Promoting Services of Businesses and Professionals Across Industries',
    description:
      'Here, you can easily offer your services to clients in any field — beauty, healthcare, sports, education, consulting, automotive, IT, and many more. Join us and expand your audience together with KeepSer!',
    registerAsCompanyButtonLabel: 'Register as a Company',
    registerAsMasterButtonLabel: 'Register as a Professional',
    example1title: 'Brian Green',
    example1attribute1: 'Chiropractor',
    example1attribute2: 'Top Professional',
    example1attribute3: 'Trauma Specialist',
    example1attribute4: 'Orthopedist',
    example2title: 'Beauty Salon “G-Bar”',
    example2subcategory1: 'Manicure',
    example2subcategory2: 'Pedicure',
    example2subcategory3: 'Makeup',
    example2subcategory4: 'Hairstyling',
    example2subcategory5: 'Cosmetics',
    example2Master1Name: 'Maggie Carroll',
    example2Master1Experience: 'Top Professional / Specialist',
    example2Master2Name: 'Anna Evans',
    example2Master2Experience: 'Beginner',
    example3title: 'Violet Carpenter',
    example3attribute1: 'English Teacher',
    example3attribute2: 'Top Professional',
    example3attribute3: 'Spanish Teacher',
    example3attribute4: 'Specialist',
    pros: {
      title: 'Benefits of Our Platform for You',
      item1Title: 'Promote Your Business',
      item1Details:
        'KeepSer automatically promotes your business, helping you attract new clients without additional costs.',
      item2Title: 'Service Bookings',
      item2Details:
        'KeepSer offers a convenient booking system: clients schedule appointments themselves, and you stay informed with automatic updates.',
      item3Title: 'Calendar and Schedule Management',
      item3Details: 'KeepSer’s calendar helps you plan, manage bookings, and synchronize your schedule.',
      item4Title: 'Team Management',
      item4Details:
        'KeepSer provides team management tools: add staff, define their roles, monitor workload, and automate routine tasks.',
      item5Title: 'Improved Productivity',
      item5Details: 'Automating routine processes allows you to focus on growing your business.',
      item6Title: 'Convenience for Clients',
      item6Details: 'Easy booking makes your business more appealing to clients.',
      item7Title: 'Data Security',
      item7Details: 'We adhere to high privacy standards, protecting your company’s and clients’ data.',
    },
    callToActionTitle: 'Ready to Transform Your Business for the Better?',
    callToActionDescription: 'Sign up on KeepSer for free or schedule a demo to explore all the platform’s features!',
  },

  aboutPage: {
    title: 'About the Platform',
    description:
      'KeepSer is an online service for finding services. The platform connects service seekers who need a task completed or require any type of service with skilled professionals or companies looking for clients or simply wanting to help.',
    goal: 'Currently, our main goal is to support people and businesses during the war! So that everyone can find each other more easily.',
    actionSectionTitle: 'Find or offer your services on KeepSer!',
    searchButtonLabel: 'Search for Services',
    offerButtonLabel: 'Offer Services',
  },

  nav: {
    servicesLabel: 'Services',
    aboutLabel: 'About Us',
    forCompaniesLabel: 'For Companies',
    termsAndConditionsLabel: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
  },

  appBar: {
    actionBtnLabel: 'I Want to Help',
    logOutBtnLabel: 'Log Out',
  },

  searchSection: {
    searchPlaceholder: 'Service...',
    citySelectorPlaceholder: 'Choose City...',
    categoryListTitle: 'Or Choose Category:',
  },

  overviewSection: {
    title1: "Supporting the country's economy during the war",
    title2: 'Attracting new customers',
    title3: 'Helping refugees and affected individuals',
  },

  companyCabinetPage: {
    title: 'Personal Dashboard',
    infoTab: 'Company Information',
    mastersTab: 'Specialists',
    companyInfoTitle: 'Company Information',
    notActiveAccountTitle: 'Account Inactive',
    activeAccountTitle: 'Account Active',
    notActiveAccountDescription: 'To activate your account, please fill in all the required information',
    avatarTitle: 'Your Photo / Logo',
    avatarDescription: 'The photo must be in .jpg or .png format. Maximum size 10 MB.',
    avatarAddButtonLabel: 'Choose Photo',
    avatarChangeButtonLabel: 'Change Photo',
    nameFieldLabel: 'Name',
    nameFieldPlaceholder: 'Enter Company Name',
    contactNameFieldLabel: "Representative's Full Name",
    contactNameFieldPlaceholder: "Enter Representative's Full Name",
    cityFieldLabel: 'City of Operation',
    cityFieldPlaceholder: 'Choose City',
    addressFieldLabel: 'Address',
    addressFieldPlaceholder: 'Enter Address',
    phoneNumberLabel: 'Phone Number',
    emailLabel: 'Email',
    emailPlaceholder: 'Your Email',
    aboutLabel: 'About the Company',
    aboutPlaceholder: 'Tell Us About Yourself',
    scheduleTitle: 'Working Hours',
    scheduleSubtitle: 'Day',
    scheduleSelectLabel: 'Day',
    scheduleAddButtonLabel: 'Add',
    scheduleChipListTitle: 'Schedule',
    fromLabel: 'Time (from)',
    toLabel: 'Time (to)',
    from: 'from',
    to: 'to',
    addMasterButtonLabel: '+ Add Specialist',
    masterSearchPlaceholder: 'Enter Name',
    masterStatusActive: 'Active',
    masterStatusDraft: 'Draft',
    mastersEmptyStateMessage: "You haven't added any specialists yet",
  },

  masterCabinetPage: {
    backButtonLabel: 'Back to Dashboard',
    title: 'Personal Dashboard',
    companyCreateMasterTitle: 'Add Specialist',
    companyMasterTitle: 'Specialist {{id}}',
    infoTab: 'My Information',
    companyMasterInfoTab: "Specialist's Information",
    servicesTab: 'My Services',
    companyMasterServicesTab: "Specialist's Services",
    notActiveAccountTitle: 'Account Inactive',
    activeAccountTitle: 'Account Active',
    notActiveAccountDescription: 'To activate your account, please fill in all the required information',
    infoTitle: 'My Information',
    companyMasterInfoTitle: 'My Information',
    avatarTitle: 'Photo',
    companyMasterAvatarTitle: 'Photo',
    avatarDescription: 'The photo must be in .jpg or .png format.',
    avatarAddButtonLabel: 'Choose Photo',
    avatarChangeButtonLabel: 'Change Photo',
    contactNameFieldLabel: 'First and Last Name',
    contactNameFieldPlaceholder: 'First and Last Name',
    companyMasterContactNameFieldPlaceholder: "Master's first and last name",
    cityFieldLabel: 'City you work in',
    companyMasterCityFieldLabel: 'City they work in',
    cityFieldPlaceholder: 'Select city',
    phoneNumberLabel: 'Phone Number',
    emailLabel: 'Email',
    emailPlaceholder: 'Your email',
    companyMasterEmailPlaceholder: 'Email',
    aboutLabel: 'About the master',
    companyMasterAboutLabel: 'About the master',
    aboutPlaceholder: 'Tell us about the master...',
    companyMasterAboutPlaceholder: 'Tell us about the master...',
    addMasterButton: 'Add Master',
    deleteMasterButton: 'Delete Master',
    scheduleTitle: 'Work Schedule',
    scheduleSubtitle: 'Day',
    scheduleSelectLabel: 'Day',
    scheduleAddButtonLabel: 'Add',
    scheduleChipListTitle: 'Schedule',
    fromLabel: 'From',
    toLabel: 'To',
    from: 'from',
    to: 'to',
    activitiesTitle: 'Activities and Experience',
    activitiesDescription: 'Choose a category to specify your experience and list your services.',
    companyMasterActivitiesDescription: "Choose a category to specify the master's experience and list their services.",
    chooseCategoryLabel: 'Choose category',
    addExperienceButtonLabel: 'Add',
    deleteExperienceTitle: 'Are you sure you want to delete?',
    deleteExperienceDescription: 'Deleting a subcategory will also delete all services and work examples added to it.',
    servicesTabEmptyStateMessage:
      'To add your services, you need to fill out information about your activities and experience level in the main form.',
    companyMasterServicesTabEmptyStateMessage:
      "To add services, you need to fill out information about the master's activities and experience level in the main form.",
    servicesTabEmptyStateButton: 'Choose activities',
    servicesTabNoServicesTitle: "You haven't added any services yet",
    addServiceButtonLabel: 'Add Service',
    addExamplesButtonLabel: 'Add Work Examples',
    extraImagesLabel: '+ {{count}} photos',
    confirmDeleteTitle: 'Are you sure you want to delete the service?',
    confirmDeleteMasterTitle: 'Are you sure you want to delete the master?',
  },

  clientCabinetPage: {
    title: 'Personal Cabinet',
    infoTab: 'My Info',
    reservationsTab: 'My Bookings',
    infoTitle: 'My Info',
    avatarTitle: 'My Photo',
    avatarDescription: 'The photo should be in .jpg or .png format. Size no more than 10 MB.',
    avatarAddButtonLabel: 'Choose Photo',
    avatarChangeButtonLabel: 'Change Photo',
    contactNameFieldLabel: 'First and Last Name',
    contactNameFieldPlaceholder: 'Enter your first and last name',
    phoneNumberLabel: 'Phone Number',
    emailLabel: 'Email',
    emailPlaceholder: 'Your email',
    activeReservationsLabel: 'Active',
    archivedReservationsLabel: 'Past',
    cancelReservationButtonLabel: 'Cancel Booking',
    bookAgainButtonLabel: 'Book Again',
    noReservationsLabel: "You don't have any bookings yet.",
  },

  addServicePage: {
    backButtonLabel: 'Back to Cabinet',
    title: 'Add Service',
    infoTitle: 'General Information',
    nameLabel: 'Enter Name',
    namePlaceholder: 'Service Name',
    durationLabel: 'Duration',
    priceLabel: 'Price ({{currency}})',
    pricePlaceholder: 'Service Price',
    descriptionLabel: 'About the Service (optional)',
    descriptionPlaceholder: 'Tell us about the service...',
    examplesTitle: 'Work Examples',
    examplesSubtitle:
      'You can add up to 20 photos with examples of your work for one subcategory. Photo format: .jpg, .png. Each photo no more than 10 MB.',
    addPhotoButtonLabel: 'Upload Photo',
    addButtonLabel: 'Add Service',
    editButtonLabel: 'Save',
  },

  footer: {
    subtitle: 'If you have any questions, you can always reach out to us via email!',
    email: 'Email',
    button: 'Contact Us',
    copyright: 'All rights reserved, 2022',
  },

  authenticateModal: {
    title: 'Authorization',
    subtitle: 'Currently, only Google account authorization is available',
    button: 'Google',
    termsAndConditionsDescription: 'By clicking the button, you agree to our',
    termsAndConditionsButtonLabel: 'Terms of Use',
    selectAccountTypeModalTitle: 'Choose Account Type',
    selectAccountTypeModalSubTitle: 'Your account is not yet registered in our system',
    cancel: 'Cancel',
    createAccount: 'Create Account',
  },

  bookServiceModal: {
    title: 'Book a Service',
    subtitle: 'To book a service, register in our system. Currently, only Google account authorization is available',
    buttonLabel: 'Google',
    termsAndConditionsDescription: 'By clicking the button, you agree to our',
    termsAndConditionsButtonLabel: 'Terms of Use',
    phoneSubtitle: 'Enter your phone number for booking',
    phoneNumberLabel: 'Phone Number',
    submitButtonLAbel: 'Choose a date',
  },

  bookServicePage: {
    title: 'Book a service',
    firstStepTitle: 'Choose a date',
    secondStepTitle: 'You have chosen',
    timestampsTitle: 'Available time',
    submitButtonLabel: 'Book',
    successModalTitle: 'Your service has been successfully booked!',
    successModalButtonLabel: 'Go to main',
  },

  bookPersonalSlotPage: {
    title: 'Create Event',
    titleLabel: 'Title',
    titlePlaceholder: 'Enter title...',
    dateLabel: 'Date',
    from: 'Time (from)',
    to: 'Time (to)',
    masterLabel: 'Master',
    masterPlaceholder: 'Select master',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Add description...',
    addMasterButton: 'Add Master',
    submitButton: 'Save',
  },

  calendarPage: {
    title: 'Calendar',
    scheduleTab: 'Schedule',
    newOrdersTab: 'New Requests',
    scheduleVariantTitle: 'Period',
  },

  reservationDetailsPage: {
    backButtonLabel: 'Back to Calendar',
    title: 'Reservation Details',
    client: 'Client',
    cancelButton: 'Cancel Reservation',
    submitButton: 'Confirm Reservation',
  },

  currency: {
    uah: 'UAH',
    usd: 'USD',
  },

  phoneNumberVerification: {
    title: 'Phone Number',
    verificationTitle: 'Enter the code from SMS',
    resendCodeText: 'You can resend the code in {{count}} second',
    resendCodeText_other: 'You can resend the code in {{count}} seconds',
    verificationSubtitle: 'To confirm, please enter the code sent via SMS to your number',
    errorText: 'The code entered is incorrect. Please try again.',
    resendCodeButtonLabel: 'Resend code',
    loadingStateMessage: 'Please wait...',
    incorrectNumberErrorText: 'The phone number is incorrect. Please enter a valid number.',
  },
};
