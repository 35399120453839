import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import useReservationsTab from './useReservationsTab';
import mapReservationModelToViewModel from './mapReservationModelToViewModel';
import { StyledReservationList } from './styled';
import ReservationCard from './ReservationCard';
import ReservationCardSkeleton from './ReservationCard/Skeleton';
import useColumnsCount from './ReservationCard/useColumnsCount';
import generateIncrementalArray from 'utils/generateIncrementalArray';
import DeleteModal from 'components/@common/DeleteModal';
import { OutputReservationDto } from 'types/reservation';

const ReservationsTab: React.FC = () => {
  const { t } = useTranslation();
  const [canceledReservation, setCanceledReservation] = useState<OutputReservationDto | null>(null);

  const {
    reservations: { active, archived },
    loading,
    handleBookAgain,
    handleCancelReservation,
    cancelReservationLoadings,
    handleServiceClick,
  } = useReservationsTab();

  const columnCount = useColumnsCount();

  return (
    <>
      <Stack gap="20px">
        {loading && (
          <>
            <Stack gap="12px">
              <Typography variant="subtitle1">{t('clientCabinetPage.activeReservationsLabel')}</Typography>
              <StyledReservationList>
                {generateIncrementalArray(columnCount).map(item => (
                  <ReservationCardSkeleton key={item} />
                ))}
              </StyledReservationList>
            </Stack>
            <Stack gap="12px">
              <Typography variant="subtitle1">{t('clientCabinetPage.archivedReservationsLabel')}</Typography>
              <StyledReservationList>
                {generateIncrementalArray(columnCount).map(item => (
                  <ReservationCardSkeleton key={item} />
                ))}
              </StyledReservationList>
            </Stack>
          </>
        )}
        {!loading && !active.length && !archived.length && (
          <Typography variant="body1" color="primary.dark2">
            {t('clientCabinetPage.noReservationsLabel')}
          </Typography>
        )}
        {!!active.length && (
          <Stack gap="12px">
            <Typography variant="subtitle1">{t('clientCabinetPage.activeReservationsLabel')}</Typography>
            <StyledReservationList>
              {active.map(item => {
                const isCancelLoading = cancelReservationLoadings.includes(item.id);

                return (
                  <ReservationCard
                    key={item.id}
                    {...mapReservationModelToViewModel(item.service, dayjs(item.date), item.timeSlot)}
                    loading={isCancelLoading}
                    onClick={() => handleServiceClick(item.service.id)}
                    onButtonClick={() => setCanceledReservation(item)}
                  />
                );
              })}
            </StyledReservationList>
          </Stack>
        )}
        {!!archived.length && (
          <Stack gap="12px">
            <Typography variant="subtitle1">{t('clientCabinetPage.archivedReservationsLabel')}</Typography>
            <StyledReservationList gap="20px">
              {archived.map(item => (
                <ReservationCard
                  key={item.id}
                  {...mapReservationModelToViewModel(item.service, dayjs(item.date), item.timeSlot)}
                  onClick={() => handleServiceClick(item.service.id)}
                  onButtonClick={() => handleBookAgain(item.service.id)}
                  archived
                />
              ))}
            </StyledReservationList>
          </Stack>
        )}
      </Stack>
      <DeleteModal
        open={!!canceledReservation}
        title={t('confirmCancelReservationTitle')}
        confirmLabel={t('confirmCancelReservationButtonLabel')}
        description={<Typography variant="subtitle1">{canceledReservation?.service.name}</Typography>}
        onSubmit={async () => {
          if (canceledReservation) {
            await handleCancelReservation(canceledReservation.id);
          }
          setCanceledReservation(null);
        }}
        onClose={() => setCanceledReservation(null)}
      />
    </>
  );
};

export default ReservationsTab;
